var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.budget.data
    ? _c(
        "div",
        [
          _c(
            "b-row",
            {
              class: { searched: _vm.budget.is_selected },
              staticStyle: {
                border: "1px solid #dfdfdf !important",
                margin: "0",
              },
            },
            [
              _c(
                "b-col",
                {
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "mr-2",
                      staticStyle: { display: "inline-block" },
                      style: { marginLeft: _vm.nodeMargin + "px" },
                      attrs: { col: "2" },
                    },
                    [
                      !_vm.showChildren
                        ? _c("SvgPlus", {
                            style: {
                              visibility: _vm.budget.children
                                ? "visible"
                                : "hidden",
                            },
                            on: { clicked: _vm.toggleChildren },
                          })
                        : _vm._e(),
                      _vm.showChildren
                        ? _c("SvgMinus", {
                            style: {
                              visibility: _vm.budget.children
                                ? "visible"
                                : "hidden",
                            },
                            on: { clicked: _vm.toggleChildren },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "border-0 px-1 py-0",
                      staticStyle: { border: "0px !important" },
                      attrs: { col: "10" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          disabled: _vm.budget.data.category == 0,
                          type: "text",
                          id: "costCenterT",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updatedCostCenter(_vm.budget.data)
                          },
                        },
                        model: {
                          value: _vm.budget.data.costCenter,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "costCenter", $$v)
                          },
                          expression: "budget.data.costCenter",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "px-1",
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { cols: "2" },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      disabled:
                        (_vm.budget.data.contentType == 1 &&
                          _vm.budget.data.isSum &&
                          _vm.budget.data.parentId != 0) ||
                        _vm.budget.data.category == 0,
                      type: "text",
                      id: "costCenterT",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.updatedCostCenter(_vm.budget.data)
                      },
                    },
                    model: {
                      value: _vm.budget.data.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.budget.data, "description", $$v)
                      },
                      expression: "budget.data.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "px-1 py-0 text-center",
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { cols: "1" },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled: _vm.budget.data.category == 0,
                      options: _vm.contentTypeMenu,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.updatedContentTypeAndCoTwo(_vm.budget.data)
                      },
                    },
                    model: {
                      value: _vm.budget.data.contentType,
                      callback: function ($$v) {
                        _vm.$set(_vm.budget.data, "contentType", $$v)
                      },
                      expression: "budget.data.contentType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "px-1",
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: {
                    cols: `${
                      _vm.showCostCenterA ||
                      _vm.showCostCenterB ||
                      _vm.showCostCenterC
                        ? 1
                        : 2
                    }`,
                  },
                },
                [
                  _vm.budget.data.contentType != 1
                    ? _c("b-form-select", {
                        attrs: {
                          options: _vm.co2CategoriesMenu,
                          disabled: _vm.budget.data.category == 0,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updatedContentTypeAndCoTwo(
                              _vm.budget.data
                            )
                          },
                        },
                        model: {
                          value: _vm.budget.data.coTwoCategory,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "coTwoCategory", $$v)
                          },
                          expression: "budget.data.coTwoCategory",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.showCostCenterA
                ? _c(
                    "b-col",
                    {
                      staticClass: "px-1",
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          disabled: _vm.budget.data.category == 0,
                          type: "text",
                          id: "costCenterTA",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updatedCostCenter(_vm.budget.data)
                          },
                        },
                        model: {
                          value: _vm.budget.data.costCenterA,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "costCenterA", $$v)
                          },
                          expression: "budget.data.costCenterA",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showCostCenterB
                ? _c(
                    "b-col",
                    {
                      staticClass: "px-1",
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          disabled: _vm.budget.data.category == 0,
                          type: "text",
                          id: "costCenterTB",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updatedCostCenter(_vm.budget.data)
                          },
                        },
                        model: {
                          value: _vm.budget.data.costCenterB,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "costCenterB", $$v)
                          },
                          expression: "budget.data.costCenterB",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showCostCenterC
                ? _c(
                    "b-col",
                    {
                      staticClass: "px-1",
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          disabled: _vm.budget.data.category == 0,
                          type: "text",
                          id: "costCenterTC",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updatedCostCenter(_vm.budget.data)
                          },
                        },
                        model: {
                          value: _vm.budget.data.costCenterC,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "costCenterC", $$v)
                          },
                          expression: "budget.data.costCenterC",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                {
                  staticClass: "px-1 py-0 text-center",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                  },
                  attrs: { cols: "1" },
                },
                [
                  _vm.budget.data.contentType != 1
                    ? _c("b-form-checkbox", {
                        staticClass: "pj-cb",
                        staticStyle: { border: "none !important" },
                        attrs: {
                          size: "lg",
                          disabled:
                            _vm.budget.data.isSum ||
                            _vm.budget.data.category == 0,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updateappChoice(_vm.budget.data)
                          },
                        },
                        model: {
                          value: _vm.budget.data.appChoice,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "appChoice", $$v)
                          },
                          expression: "budget.data.appChoice",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "px-1",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _vm.isEnglish
                    ? _c("VueNumberFormat", {
                        staticClass: "form-control text-right",
                        attrs: {
                          options: {
                            precision: 2,
                            prefix: "",
                            isInteger: false,
                            decimal: ".",
                            thousand: ",",
                          },
                          disabled:
                            _vm.budget.data.isSum ||
                            _vm.isLocked ||
                            _vm.budget.data.category == 0,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.updatedData(
                              _vm.budget.data,
                              $event,
                              true
                            )
                          },
                        },
                        model: {
                          value: _vm.budget.data.initialBudget,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "initialBudget", $$v)
                          },
                          expression: "budget.data.initialBudget",
                        },
                      })
                    : _c("VueNumberFormat", {
                        staticClass: "form-control text-right",
                        attrs: {
                          options: {
                            precision: 2,
                            prefix: "",
                            isInteger: false,
                            decimal: ",",
                            thousand: ".",
                          },
                          disabled:
                            _vm.budget.data.isSum ||
                            _vm.isLocked ||
                            _vm.budget.data.category == 0,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.updatedData(
                              _vm.budget.data,
                              $event,
                              true
                            )
                          },
                        },
                        model: {
                          value: _vm.budget.data.initialBudget,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "initialBudget", $$v)
                          },
                          expression: "budget.data.initialBudget",
                        },
                      }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "px-1",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _vm.isEnglish
                    ? _c("VueNumberFormat", {
                        staticClass: "form-control text-right",
                        attrs: {
                          options: {
                            precision: 2,
                            prefix: "",
                            isInteger: false,
                            decimal: ".",
                            thousand: ",",
                          },
                          disabled:
                            _vm.budget.data.isSum ||
                            _vm.budget.data.category == 0,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.updatedData(
                              _vm.budget.data,
                              $event,
                              false
                            )
                          },
                        },
                        model: {
                          value: _vm.budget.data.estimatedFinalCost,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "estimatedFinalCost", $$v)
                          },
                          expression: "budget.data.estimatedFinalCost",
                        },
                      })
                    : _c("VueNumberFormat", {
                        staticClass: "form-control text-right",
                        attrs: {
                          options: {
                            precision: 2,
                            prefix: "",
                            isInteger: false,
                            decimal: ",",
                            thousand: ".",
                          },
                          disabled:
                            _vm.budget.data.isSum ||
                            _vm.budget.data.category == 0,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.updatedData(
                              _vm.budget.data,
                              $event,
                              false
                            )
                          },
                        },
                        model: {
                          value: _vm.budget.data.estimatedFinalCost,
                          callback: function ($$v) {
                            _vm.$set(_vm.budget.data, "estimatedFinalCost", $$v)
                          },
                          expression: "budget.data.estimatedFinalCost",
                        },
                      }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "px-1",
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { cols: 1 },
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.budget.data.departmentName) +
                      "\n\t\t"
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "px-1 text-center",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                  },
                },
                [
                  _vm.budget.data.category != 0
                    ? _c(
                        "b-dropdown",
                        {
                          staticStyle: {
                            "border-right": "0px !important",
                            "padding-top": "0px !important",
                          },
                          attrs: {
                            "no-caret": "",
                            dropleft: "",
                            offset: "25",
                            variant: "none",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [_c("more-vertical")]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            664767440
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addChild(_vm.budget)
                                },
                              },
                            },
                            [
                              _c("plus-circle"),
                              _vm._v("   "),
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(2000, "Create a sub category")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.clickedRow(_vm.budget.data)
                                },
                              },
                            },
                            [
                              _c("edit"),
                              _vm._v("   "),
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(2001, "Additional settings")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          (_vm.budget.data.IsLinkedToFunction == false &&
                            _vm.budget.data.isLinkedToDepartment == false) ||
                          (_vm.budget.data.IsLinkedToFunction == true &&
                            _vm.budget.data.isLinkedToDepartment == false)
                            ? _c(
                                "b-dropdown-item-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.cloneCat(_vm.budget.data)
                                    },
                                  },
                                },
                                [
                                  _c("copy"),
                                  _vm._v("   "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(2002, "Duplicate"))
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          (_vm.budget.data.IsLinkedToFunction == false &&
                            _vm.budget.data.isLinkedToDepartment == false) ||
                          (_vm.budget.data.IsLinkedToFunction == true &&
                            _vm.budget.data.isLinkedToDepartment == false)
                            ? _c(
                                "b-dropdown-item-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.delItemConfirm(_vm.budget)
                                    },
                                  },
                                },
                                [
                                  _c("trash2"),
                                  _vm._v("   "),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.FormMSG(2003, "Remove"))),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.hasChildren && _vm.showChildren
            ? _c(
                "div",
                _vm._l(_vm.budget.children, function (child) {
                  return _c("tree-node-budget", {
                    key: child.id,
                    attrs: {
                      childLevel: _vm.childLevel + 1,
                      displayAllCategories: _vm.displayAllCategories,
                      showCostCenterA: _vm.showCostCenterA,
                      showCostCenterB: _vm.showCostCenterB,
                      showCostCenterC: _vm.showCostCenterC,
                      showchildrenOnSearch: _vm.showchildrenOnSearch,
                      depNumber: _vm.depNumber,
                      budgete: child,
                      spacing: _vm.spacing + 20,
                    },
                    on: {
                      "categorie-tree-view:addChild": _vm.addChild,
                      "categorie-tree-view:delete": _vm.deleteChild,
                      "categorie-tree-view:select": _vm.selectChild,
                      "categorie-tree-view:SpecialUpdate":
                        _vm.updatedContentTypeAndCoTwo,
                      "categorie-tree-view:update": _vm.updatedCostCenter,
                      "categorie-tree-view:clone": _vm.cloneCat,
                      "categorie-tree-view:AmountUpdate": _vm.updatedChildData,
                      "categorie-tree-view:setAsFunc": _vm.setAsFunc,
                      "categorie-tree-view:setAsDep": _vm.setAsDep,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }