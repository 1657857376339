<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.btn {
	margin-bottom: 8px;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	padding: 1.25rem;
	flex: 1 1 auto;
}
.border {
	border: 1px solid black;
	border-radius: 5px;
}
.fontBold {
	font-weight: bolder;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<div class="animated fadeIn">
			<loading :active.sync="isLoading" :is-full-page="true" color="#00A09C"></loading>
			<b-row v-if="Deps" class="container-layout">
				<b-col md="12">
					<div class="card card-border-blue-light">
						<b-form>
							<b-row>
								<b-col md="12" v-if="!isFilmSingle">
									<h6>
										<b>{{ this.FormMSG(2303, 'Department selection:') }}</b>
									</h6>
								</b-col>
								<b-col md="4" v-if="!isFilmSingle">
									<b-form-group>
										<b-form-select
											:disabled="isFilmSingle || isFilmSingleFree"
											v-model="curDep.value"
											:options="Deps"
											id="depChoice"
											v-on:change="depChanged"
											size="md"
										></b-form-select>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-input-group v-if="$screen.width > 992">
										<b-form-input
											v-model="filter"
											@keyup.enter="setSearch(filter)"
											type="search"
											id="filterInput"
											:placeholder="this.FormMSG(37, 'Type to Search')"
										></b-form-input>
										<b-input-group-append>
											<b-button @click="setSearch">
												<search width="20" height="20" />
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</b-col>
								<b-col md="4">
									<b-button @click="createNewCats()" variant="primary" block v-if="!isFilmSingle"
										>{{ this.FormMSG(34, 'Create a new category for') }} {{ curDepMessage }}</b-button
									>
									<b-button @click="createNewCats()" variant="primary" block v-else
										>{{ this.FormMSG(340, 'Create a new category') }}
									</b-button>
								</b-col>
							</b-row>

							<!-- <hr v-if="displayAllCategories" /> -->
							<b-row class="pb-1 pt-3 pl-4" v-if="!isFilmSingle && !isFilmSingleFree">
								<b-form-group class="mr-3">
									<b-form-checkbox size="lg" id="disAll" v-model="displayAllCategories" v-on:change="displayAllCats()" class="pj-cb"
										><div style="margin-top: 1px">{{ this.FormMSG(24, 'Display all') }}</div></b-form-checkbox
									>
								</b-form-group>
								<b-form-group class="mr-3">
									<b-form-checkbox size="lg" id="Cc1" v-model="showCostCenterA" class="pj-cb"
										><div style="margin-top: 1px">{{ this.FormMSG(2400, 'Show Cost Center A') }}</div></b-form-checkbox
									>
								</b-form-group>
								<b-form-group class="mr-3">
									<b-form-checkbox size="lg" id="Cc2" v-model="showCostCenterB" class="pj-cb"
										><div style="margin-top: 1px">{{ this.FormMSG(2401, 'Show Cost Center B') }}</div></b-form-checkbox
									>
								</b-form-group>
								<b-form-group>
									<b-form-checkbox size="lg" id="Cc3" v-model="showCostCenterC" class="pj-cb"
										><div style="margin-top: 1px">{{ this.FormMSG(2402, 'Show Cost Center C') }}</div></b-form-checkbox
									>
								</b-form-group>
								<b-col class="text-right">
									<b-button @click="generateCarbon()">
										<b-spinner v-if="carbonInProccess" small />
										{{ FormMSG(546513, 'Generate forecast carbon entry based on Budget') }}
									</b-button>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="12" xl="12">
									<CardListBuilder v-if="$screen.width < 992" :items="Cats" :fields="catMobileFields">
										<template slot="actions" slot-scope="data">
											<b-button variant="primary" @click="rowClickedMobile(data.item)" size="sm">
												<i class="icon-eye"></i>
											</b-button>
											<b-button
												variant="danger"
												size="sm"
												v-if="
													data.item.contentType === 0 &&
													data.item.category !== 3 &&
													data.item.category !== 4 &&
													data.item.category !== 9 &&
													(data.item.department === 0 || data.item.type === 1)
												"
												@click="delItemConfirm(data.item)"
											>
												<i class="icon-trash"></i>
											</b-button>
										</template>
									</CardListBuilder>
									<TableBudgetCategory
										v-if="$screen.width > 992"
										:categorie="perCats"
										:isLoading="isLoading"
										:displayAllCategories="displayAllCategories"
										:filter="searchData"
										:depNumber="curDep.value"
										:showCostCenterA="showCostCenterA"
										:showCostCenterB="showCostCenterB"
										:showCostCenterC="showCostCenterC"
										@categorie-tree-view:select="SelectedCategorie"
										@categorie-tree-view:delete="delItemConfirm"
										@categorie-tree-view:update="updatedCostCenter"
										@categorie-tree-view:addChild="addChild"
										@categorie-tree-view:clone="cloneCats"
										@categorie-tree-view:SpecialUpdate="updatedContentTypeAndCoTwo"
										@categorie-tree-view:AmountUpdate="updateAmount"
										@categorie-tree-view:setAsDep="setAsDep"
										@categorie-tree-view:setAsFunc="setAsFunc"
									/>
									<!-- <tree-table
										sortMode="single"
										removableSort
										:indentation="1"
										filterMode="lenient"
										:resizableColumns="true"
										:filters="filters"
										class="p-treetable-sm"
										:lazy="true"
										showGridlines
										:value="perCats"
									>
										 <template #header>
											<div class="text-right">
												<div class="p-input-icon-left">
													<i class="pi pi-search"></i>
													<InputText v-model="filters['global']" placeholder="Global Search" size="50" />
												</div>
											</div>
										</template> 
										<Column
											sortable
											bodyClass="align-items-center"
											:bodyStyle="{ display: 'flex' }"
											field="costCenter"
											:header="FormMSG(1100, 'Cost Center')"
											:expander="true"
										>
											<template #body="slotProps">
												<b-form-input
													:disabled="slotProps.node.data.category == 0"
													v-model="slotProps.node.data.costCenter"
													type="text"
													@change="updatedCostCenter(slotProps.node.data)"
													id="costCenterT"
												></b-form-input>
											</template>
										</Column>
										<Column :sortable="true" field="description" :header="FormMSG(113, 'Name')">
											<template #filter>
												<InputText
													type="text"
													v-model="filters['data.description']"
													class="p-column-filter"
													placeholder="Filter by name"
												/>
											</template>
											<template #body="slotProps">
												<b-form-input
													v-model="slotProps.node.data.description"
													@change="updatedCostCenter(slotProps.node.data)"
													:disabled="
														(slotProps.node.data.contentType == 1 &&
															slotProps.node.data.isSum &&
															slotProps.node.data.parentId != 0) ||
														slotProps.node.data.category == 0
													"
													type="text"
													id="costCenterT"
												></b-form-input> </template
										></Column>
										<Column sortable field="contentType" :header="FormMSG(11213, 'Type')">
											<template #body="slotProps">
												<b-form-select
													@change="updatedContentTypeAndCoTwo2(slotProps.node.data)"
													v-model="slotProps.node.data.contentType"
													:disabled="slotProps.node.data.category == 0"
													:options="contentTypeMenu"
												/>
											</template>
										</Column>
										<Column sortable field="coTwoCategory" :header="FormMSG(1112, 'CO2 Category')">
											<template #body="slotProps">
												<b-form-select
													@change="updatedContentTypeAndCoTwo2(slotProps.node.data)"
													v-if="slotProps.node.data.contentType != 1"
													v-model="slotProps.node.data.coTwoCategory"
													:options="co2CategoriesMenu"
													:disabled="slotProps.node.data.category == 0"
												/>
											</template>
										</Column>
										<Column sortable field="appChoice" :header="FormMSG(1282, 'Visible')">
											<template #body="slotProps">
												<div class="text-center">
													<b-button
														@click="updateappChoice(slotProps.node.data)"
														v-if="slotProps.node.data.contentType != 1"
														:disabled="slotProps.node.data.isSum || slotProps.node.data.category == 0"
														style="margin-top: 6px"
														label-for="visInApp1"
														:variant="`${slotProps.node.data.appChoice ? 'primary' : 'outline-danger'}`"
														size="sm"
													>
														<check v-if="slotProps.node.data.appChoice" :size="15" />
														<deny v-else :size="15" />
													</b-button>
												</div>
											</template>
										</Column>
										<Column sortable field="initialBudget" :header="FormMSG(1213, 'Amount')">
											<template #body="slotProps">
												<VueNumberFormat
													v-if="isEnglish"
													v-model="slotProps.node.data.initialBudget"
													:options="{ precision: 2, prefix: '', isInteger: false, decimal: '.', thousand: ',' }"
													@update:value="updatedData(slotProps.node.data, $event, true)"
													:disabled="slotProps.node.data.isSum || isLocked || slotProps.node.data.category == 0"
													class="form-control text-right"
												></VueNumberFormat>
												<VueNumberFormat
													v-else
													v-model="slotProps.node.data.initialBudget"
													:options="{ precision: 2, prefix: '', isInteger: false, decimal: ',', thousand: '.' }"
													@update:value="updatedData(slotProps.node.data, $event, true)"
													:disabled="slotProps.node.data.isSum || isLocked || slotProps.node.data.category == 0"
													class="form-control text-right"
												></VueNumberFormat>
											</template>
										</Column>
										<Column sortable field="estimatedFinalCost" :header="FormMSG(1219, 'EFC')">
											<template #body="slotProps">
												<VueNumberFormat
													v-if="isEnglish"
													v-model="slotProps.node.data.initialBudget"
													@update:value="updatedData(slotProps.node.data, $event, false)"
													:options="{ precision: 2, prefix: '', isInteger: false, decimal: '.', thousand: ',' }"
													:disabled="slotProps.node.data.isSum || isLocked || slotProps.node.data.category == 0"
													class="form-control text-right"
												></VueNumberFormat>
												<VueNumberFormat
													v-else
													v-model="slotProps.node.data.initialBudget"
													@update:value="updatedData(slotProps.node.data, $event, false)"
													:options="{ precision: 2, prefix: '', isInteger: false, decimal: ',', thousand: '.' }"
													:disabled="slotProps.node.data.isSum || isLocked || slotProps.node.data.category == 0"
													class="form-control text-right"
												></VueNumberFormat>
											</template>
										</Column>
										<Column sortable field="departmentName" :header="FormMSG(1216, 'Department')"> </Column>
										<Column field="opt" bodyClass="text-center" :header="FormMSG(1229, 'Option')">
											<template #body="slotProps">
												<b-dropdown
													no-caret
													dropleft
													offset="25"
													variant="none"
													style="border-right: 0px !important; padding-top: 0px !important"
												>
													<template #button-content>
														<more-vertical v-if="slotProps.node.data.category != 0" />
													</template>
													<b-dropdown-item-button @click="addChild(slotProps.node)"
														><plus-circle /> &nbsp; <b>{{ FormMSG(2000, 'Create a sub category') }}</b></b-dropdown-item-button
													>
													<b-dropdown-item-button @click="SelectedCategorie(slotProps.node.data)"
														><edit /> &nbsp; <b>{{ FormMSG(2001, 'Additional settings') }}</b></b-dropdown-item-button
													>
													<b-dropdown-item-button
														@click="cloneCats(slotProps.node.data)"
														v-if="
															(slotProps.node.data.IsLinkedToFunction == false &&
																slotProps.node.data.isLinkedToDepartment == false) ||
															(slotProps.node.data.IsLinkedToFunction == true &&
																slotProps.node.data.isLinkedToDepartment == false)
														"
														><copy /> &nbsp; <b>{{ FormMSG(2002, 'Duplicate') }}</b></b-dropdown-item-button
													>
													<b-dropdown-item-button
														@click="delItemConfirm(slotProps.node)"
														v-if="
															(slotProps.node.data.IsLinkedToFunction == false &&
																slotProps.node.data.isLinkedToDepartment == false) ||
															(slotProps.node.data.IsLinkedToFunction == true &&
																slotProps.node.data.isLinkedToDepartment == false)
														"
														><trash2 /> &nbsp; <b>{{ FormMSG(2003, 'Remove') }}</b></b-dropdown-item-button
													>
												</b-dropdown>
											</template>
										</Column>
									</tree-table> -->
								</b-col>
							</b-row>
						</b-form>
					</div>
				</b-col>
				<b-modal
					header-class="header-class-modal-doc-package"
					centered
					size="xl"
					cancel-variant="outline-primary"
					@ok="handleUpd()"
					@cancel="deleteCatsInModal(newCat)"
					:title="FormMSG(2004, 'Budget category advance settings')"
					button-size="lg"
					:ok-title="FormMSG(201, 'Save')"
					:cancel-title="FormMSG(246, 'Cancel')"
					v-model="editModal"
					:no-close-on-backdrop="true"
				>
					<div>
						<b-row>
							<b-col md="4" sm="12">
								<b-form-group class="fontBold" :label="this.FormMSG(2500, 'Category name:')" label-for="catName">
									<b-form-input
										:disabled="newCat.isLinkedToDepartment || newCat.IsLinkedToFunction"
										v-model="newCat.description"
										type="text"
										id="catName"
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col v-if="curDep.value != 0" md="4" sm="12">
								<b-form-group class="fontBold" :label="this.FormMSG(251, 'Category type:')" label-for="catName">
									<b-form-select
										:disabled="newCat.isLinkedToDepartment || newCat.IsLinkedToFunction"
										v-model="newCat.contentType"
										:options="contentTypeMenu"
									/>
								</b-form-group>
							</b-col>
							<b-col md="4" sm="12">
								<b-form-group class="fontBold" v-if="!isFilmSingle" :label="this.FormMSG(2300, 'Department:')" label-for="depChoice">
									<b-form-input disabled v-model="newCat.departmentName" id="depChoice" size="md"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="4" sm="12">
								<b-form-group class="fontBold" :label="FormMSG(2544, 'Budget amount')">
									<b-input-group>
										<b-form-input
											:disabled="newCat.isSum || isLocked"
											@change="handleNewValue"
											v-model="newCat.initialBudget"
											type="number"
											id="spent"
										></b-form-input>
										<b-input-group-append>
											<b-input-group-text>
												<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col md="4" sm="12">
								<b-form-group class="fontBold" v-if="!isFilmSingle" :label="FormMSG(2545, 'EFC')" label-for="spent">
									<b-input-group>
										<b-form-input
											:disabled="newCat.isSum || isEditable"
											@change="handleEstimateChange"
											v-model="newCat.estimatedFinalCost"
											type="number"
											id="spent"
										></b-form-input>
										<b-input-group-append>
											<b-input-group-text>
												<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col md="4" sm="12">
								<b-form-group class="fontBold" :label="FormMSG(2546, 'Initial spent')">
									<b-input-group>
										<b-form-input :disabled="newCat.isSum" v-model="newCat.startOfPeriodValue" type="number" id="spent"></b-form-input>
										<b-input-group-append>
											<b-input-group-text>
												<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="3" sm="6" class="pt-28">
								<b-form-checkbox size="md" id="visInApp2" v-model="newCat.isSum" @change="checkChange" disabled>
									<h6 class="fontBold" style="margin-top: 2px">{{ this.FormMSG(301, 'Grouping Category') }}</h6>
								</b-form-checkbox>
							</b-col>
							<b-col md="3" sm="6">
								<b-form-group
									class="fontBold"
									v-if="newCat.IsLinkedToFunction == false && newCat.isLinkedToDepartment == false"
									:label="this.FormMSG(250, 'Parent Category:')"
									label-for="catName"
								>
									<treeselect
										class="border"
										v-model="newCat.parentId"
										:multiple="false"
										:options="treeCatsSelect"
										v-on:change="parentChange"
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col v-if="!newCat.isSum && newCat.contentType != 1" md="3" sm="6" class="pt-28">
								<b-form-checkbox size="md" id="visInApp1" v-model="newCat.appChoice">
									<h6 class="fontBold" style="margin-top: 2px">{{ this.FormMSG(30, 'Visible in App') }}</h6>
								</b-form-checkbox>
							</b-col>
							<b-col md="3" sm="6">
								<b-form-group
									class="fontBold"
									:label="this.FormMSG(3200, 'Name in App:')"
									v-if="newCat.appChoice == 1 && !newCat.isSum && newCat.contentType != 1"
									label-for="labelInApp"
								>
									<b-form-input v-model="newCat.appName" type="text" id="labelInApp"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="3" sm="6">
								<b-form-group class="fontBold" :label="this.FormMSG(29, 'Cost center:')" label-for="costCenter">
									<b-form-input v-model="newCat.costCenter" type="text" id="costCenter"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="3" sm="6">
								<b-form-group class="fontBold" v-if="!isFilmSingle" :label="this.FormMSG(72, 'Cost center A:')" label-for="costCenterA">
									<b-form-input v-model="newCat.costCenterA" type="text" id="costCenterA"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="3" sm="6">
								<b-form-group class="fontBold" v-if="!isFilmSingle" :label="this.FormMSG(73, 'Cost center B:')" label-for="costCenterB">
									<b-form-input v-model="newCat.costCenterB" type="text" id="costCenterB"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md="3" sm="6">
								<b-form-group class="fontBold" v-if="!isFilmSingle" :label="this.FormMSG(74, 'Cost center C:')" label-for="costCenterC">
									<b-form-input v-model="newCat.costCenterC" type="text" id="costCenterC"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="3" sm="6">
								<b-form-group class="fontBold" v-if="!newCat.isSum" :label="this.FormMSG(2700, 'VAT:')" label-for="catVatPercent">
									<b-input-group>
										<b-form-input v-model="newCat.vatPercent" type="number" id="catVatPercent"></b-form-input>
										<b-input-group-append>
											<b-input-group-text>
												<percent color="#06263E" width="20" height="20" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col md="3" sm="6">
								<b-form-group class="fontBold" v-if="!newCat.isSum" :label="this.FormMSG(2701, 'VAT paid back:')" label-for="catVatPercent">
									<b-input-group>
										<b-form-input v-model="newCat.vatPaidBack" type="number" id="catVatPercent"></b-form-input>
										<b-input-group-append>
											<b-input-group-text>
												<percent color="#06263E" width="20" height="20" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col v-if="newCat.contentType != 1" md="4" sm="6">
								<b-form-group class="fontBold" :label="FormMSG(120, 'CO2 Category:')">
									<b-form-select v-model="newCat.coTwoCategory" :options="co2CategoriesMenu" />
								</b-form-group>
							</b-col>
							<b-col md="4" sm="6">
								<b-form-group v-if="!newCat.isSum" class="fontBold" :label="this.FormMSG(31, 'Comment:')" label-for="catcomment">
									<b-form-textarea
										v-model="newCat.comment"
										type="textarea"
										rows="2"
										max-rows="2"
										id="catcomment"
										autocomplete="off"
									></b-form-textarea>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<div v-if="this.carbonModel === 2">
									<input-select-doconomy :doconomy-category-params="newCat.doconomyCategory" @select:category="updateDoconomyCategory" />
								</div>
							</b-col>
						</b-row>
						<b-row v-if="newCat.contentType === 0 && isCarbonClap && (!newCat.isSum || newCat.department === 0)">
							<b-col class="content-card-collapsible inside">
								<fieldset class="scheduler-border">
									<legend class="scheduler-border">
										{{ FormMSG(136523, 'Carbon clap') }}
									</legend>
									<div class="flex align-items-center py-3">
										<b-form-checkbox size="lg" id="usedForCC" v-model="newCat.usedForCarbonClapForecastReport" class="pj-cb"
											><div style="margin-top: 1px">{{ FormMSG(136522, 'Used for Carbon Clap Forecast Report ') }}</div></b-form-checkbox
										>
									</div>
								</fieldset>
							</b-col>
						</b-row>
					</div>
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(38, 'Success!')"
					class="modal-success"
					v-model="successModal"
					@ok="successModal = false"
					ok-variant="success"
					ok-only
				>
					{{ this.FormMSG(39, 'The new department name has been saved.') }}
				</b-modal>
				<b-modal
					header-class="header-class-modal-doc-package"
					:title="FormMSG(38, 'Success!')"
					class="modal-success"
					v-model="successModalDelete"
					@ok="successModalDelete = false"
					ok-variant="success"
					ok-only
				>
					{{ this.FormMSG(40, 'Your information has been deleted.') }}
				</b-modal>
			</b-row>
		</div>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import InputSelectDoconomy from '@/components/Doconomy/InputSelectDoconomy';
import { rendCurrency } from '~helpers';
import { isNil, removeAttributeTree } from '~utils';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import globalMixin from '@/mixins/global.mixin';
import { Percent, Search, X as Reset } from 'lucide-vue';
import {
	GetBudgetCategoryList,
	CheckBudgetDetailExpenseSalary,
	updateBudgetDetail,
	delBudgetDetailTree,
	addBudgetDetail,
	duplicateBudgetDetail
	// setAsDepartment,
	// setAsFunction
} from '@/cruds/categorie.crud';
import { MoreVertical, Edit, Copy, PlusCircle, Trash2, Check, X as Deny, FolderPlus, FilePlus2 as Function } from 'lucide-vue';
import * as _ from 'lodash';
import TableBudgetCategory from './TableBudgetCategory.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import CurrencySvg from '@/components/Svg/Currency';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import { generateForecastCarbonEntriesBasedOnBudget } from '@/cruds/carbon.crud';
import { getDepartments, updateDepartment, updateFunction } from '@/cruds/department.crud';
import TreeTable from 'primevue/treetable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import VueNumberFormat from '@igortrindade/vue-number-format';

export default {
	name: 'NewCategory',
	components: {
		InputSelectDoconomy,
		TableBudgetCategory,
		Treeselect,
		Loading,
		Percent,
		CurrencySvg,
		Search,
		Reset,
		Column,
		TreeTable,
		MoreVertical,
		Edit,
		Copy,
		PlusCircle,
		VueNumberFormat,
		Trash2,
		Check,
		Deny,
		FolderPlus,
		Function,
		InputText
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		caption1: {
			type: String,
			default: 'Categories for:'
		},

		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: null,
			Cats: [],
			isEqual: false,
			perCats: [],
			carbonInProccess: false,
			filters: {},
			treeCatsSelect: [],
			Deps: [],
			isLoading: false,
			editModal: false,
			showCostCenterA: false,
			showCostCenterB: false,
			showCostCenterC: false,
			parentId: 0,
			curDep: {
				value: 0,
				message: ''
			},
			// allDeps: {
			// 	value: -1,
			// 	text: ' ALL DEPARTMENTS',
			// 	message: ' ALL DEPARTMENTS'
			// },
			curPer: {
				label: 'Is top category',
				value: 0,
				id: 0
			},
			currentPage: 1,
			perPage: 0,
			warning: '',
			filter: '',
			newCreated: 0,
			displayAllCategories: false,
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			curCat: {},
			newCat: {},
			carbonModel: 0,
			searchData: '',
			isUsedBy: false,
			isNewCats: false
		};
	},
	watch: {
		filter: {
			handler(val) {
				if (val == '') {
					this.searchData = val;
				}
			}
		}
	},
	mounted() {
		this.$bus.$on('bus:projectevent', (payload) => {
			this.carbonModel = payload.carbonModel;
		});
	},
	computed: {
		isCarbonClap() {
			return store.isCarbonClapReporting();
		},
		isEnglish() {
			return store.appLanguage() == 0;
		},
		contentTypeMenu() {
			return [
				{
					value: 0,
					text: this.FormMSG(1000, 'Expense')
				},
				{
					value: 1,
					text: this.FormMSG(1001, 'Salary')
				}
			];
		},
		co2CategoriesMenu() {
			return this.FormMenu(1334);
		},
		isEditable() {
			if (store.getCurrentProjectConfig().budgetLocked == false && this.isNewCats == true) {
				return true;
			} else {
				return false;
			}
		},
		isLocked() {
			return store.getCurrentProjectConfig().budgetLocked;
		},
		curDepMessage() {
			// updating curDep.message
			for (const department of this.Deps) {
				if (this.curDep.value == department.value) {
					this.curDep.message = department.message;
					this.curDep.id = department.id;
					//console.log("this.curDep.message:", this.curDep.message);
					break;
				}
			}
			return this.curDep.message;
		},
		getLabel1() {
			const str = this.FormMSG(26, 'budget amount:');
			//console.log("in getLabel1 str:",str);
			return str;
		},
		rendConfirmModalTxt() {
			const _t = this.FormMSG;
			return {
				title: _t(56561, 'Please Confirm'),
				okTitle: _t(56562, 'YES'),
				cancelTitle: _t(56563, 'NO')
			};
		},
		baseFieldsNormal() {
			return [
				{
					key: 'description',
					label: this.FormMSG(50, 'Category name'),
					sortable: true
				},
				{
					key: 'contentType',
					label: this.FormMSG(59, 'Type'),
					formatter: (value) => {
						//console.log("value=",value);
						//  return value;
						return this.GetTextFromMenuNumberAndMenuValue(1015, value);
					},
					sortable: true
				},
				{
					key: 'isSum',
					label: this.FormMSG(510, 'Has child-category'),
					formatter: (value) => {
						if (value == true) {
							return this.FormMSG(511, 'Yes');
						} else {
							return this.FormMSG(512, 'No');
						}
					},
					sortable: true
				},
				{
					key: 'parentId',
					label: this.FormMSG(513, 'Parent Category'),
					formatter: (value) => {
						return this.findParent(value);
					},
					sortable: true
				},
				{
					key: 'category',
					label: this.FormMSG(51, 'Number'),
					sortable: true
				},
				{
					key: 'estimatedFinalCost',
					label: this.FormMSG(52, 'Amount '),
					formatter: (v) => rendCurrency(v),
					sortable: true
				},
				{
					key: 'costCenter',
					label: this.FormMSG(53, 'Cost Center'),
					sortable: true
				},
				{
					key: 'costCenterA',
					label: this.FormMSG(62, 'Cost Center A'),
					sortable: true
				},
				{
					key: 'costCenterB',
					label: this.FormMSG(60, 'Cost Center B'),
					sortable: true
				},
				{
					key: 'costCenterC',
					label: this.FormMSG(61, 'Cost Center C'),
					sortable: true
				},
				{
					key: 'vatPercent',
					label: this.FormMSG(54, 'VAT (%)'),
					sortable: false
				},
				{
					key: 'vatPaidBack',
					label: this.FormMSG(55, 'VAT PB (%)'),
					sortable: false
				},
				{
					key: 'departmentName',
					label: this.FormMSG(56, 'Department name'),
					sortable: true
				},
				{
					key: 'appName',
					label: this.FormMSG(57, 'in App'),
					sortable: false,
					formatter: (v) => v
				},
				{
					key: 'rem',
					label: this.FormMSG(58, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		baseFieldsSingleUser() {
			return [
				{
					key: 'description',
					label: this.FormMSG(50, 'Category name'),
					sortable: true
				},
				{
					key: 'category',
					label: this.FormMSG(51, 'Number'),
					sortable: true
				},
				{
					key: 'costCenter',
					label: this.FormMSG(53, 'Cost Center'),
					sortable: true
				},
				{
					key: 'vatPercent',
					label: this.FormMSG(54, 'VAT (%)'),
					sortable: false
				},
				{
					key: 'vatPaidBack',
					label: this.FormMSG(55, 'VAT PB (%)'),
					sortable: false
				},
				{
					key: 'appName',
					label: this.FormMSG(57, 'in App'),
					sortable: false,
					formatter: (v) => v
				},
				{
					key: 'rem',
					label: this.FormMSG(58, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		baseFields() {
			//return this.baseFieldsNormal
			if (!this.isFilmSingle) {
				return this.baseFieldsNormal;
			} else {
				return this.baseFieldsSingleUser;
			}
		},
		catfields() {
			return this.baseFields;
		},
		catMobileFields() {
			const keys = ['rem'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		},
		disableSave() {
			return false;
			if (this.curDep.message != this.newDep.message) {
				return false;
			} else {
				return true;
			}
		}
	},
	methods: {
		updatedData(data, evt, initialBudget) {
			if (initialBudget) {
				if (data.initialBudget === data.estimatedFinalCost) {
					data.initialBudget = evt;
					data.estimatedFinalCost = evt;
				} else {
					data.initialBudget = evt;
				}
			} else {
				data.estimatedFinalCost = evt;
			}
			this.updateAmount(data);
		},
		updateappChoice(data) {
			data.appChoice = !data.appChoice;
			this.updatedCostCenter(data);
		},
		async generateCarbon() {
			this.carbonInProccess = true;
			await generateForecastCarbonEntriesBasedOnBudget().then((res) => {
				if (res) {
					this.createToastForMobile(this.FormMSG(155, 'Success'), this.FormMSG(751414, 'Proccessed successfully'), '', 'success');
				} else {
					this.createToastForMobile(
						this.FormMSG(411, 'Error'),
						this.FormMSG(7145, 'An error occured, please try again or call your admninistrator'),
						'',
						'danger'
					);
				}
				this.carbonInProccess = false;
			});
		},
		handleEstimateChange() {
			if (this.newCat.estimatedFinalCost === this.newCat.initialBudget) {
				return;
			}
			this.isEqual = false;
		},
		handleNewValue() {
			if (this.isNewCats || this.isEqual || this.newCat.estimatedFinalCost === this.newCat.initialBudget) {
				this.isEqual = true;
				this.newCat.estimatedFinalCost = this.newCat.initialBudget;
			}
		},
		setSearch() {
			this.searchData = this.filter;
		},
		findParent(parentid) {
			let name = '';
			const datas = this.Cats;
			const parent = datas.filter((data) => data.id == parentid);
			if (parent.length > 0) {
				for (const ParName of parent) {
					return (name = ParName.description);
				}
			} else {
				return (name = this.FormMSG(166, 'Is top category'));
			}
			return name;
		},
		cloneCats(item) {
			// this.$apollo
			// 	.mutate({
			// 		mutation: mutation_cloneCat,
			// 		variables: {
			// 			BudgetDetailId: item.ID
			// 		}
			// 	})
			duplicateBudgetDetail(item.ID)
				.then((result) => {
					this.newCreated = result.id;
					this.newCat = result;
					// this.isNewCats = true
					// this.editModal = true;
					this.reloadData();
				})
				.catch((error) => {
					// alert('errora');
					console.log(error);
					this.erreur = error;
				});
		},
		setAsFunc(item) {
			let BudgetDetailId = item.id;
			let Department = item.data.department;
			// this.$apollo
			// 	.mutate({
			// 		mutation: mutation_setAsFunc,
			// 		variables: {
			// 			BudgetDetailId,
			// 			Department
			// 		}
			// 	})
			setAsFunction(BudgetDetailId, Department)
				.then((result) => {
					if (result) {
						this.loadDeps();
						this.reloadData();
					}
				})
				.catch((error) => {
					// alert('errora 2');
					console.log(error);
					this.erreur = error;
				});
		},
		setAsDep(item) {
			console.log('setAsDep triggered');
			let ChangeChildrenContentType = false;
			let BudgetDetailId = item.id;
			if (item.children) {
				const txt = this.FormMSG(900, 'Do you want to change his sub-category to salary?');

				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.rendConfirmModalTxt.title,
						size: 'md',
						buttonSize: 'md',
						okVariant: 'primary',
						cancelVariant: 'danger',
						okTitle: this.rendConfirmModalTxt.okTitle,
						cancelTitle: this.rendConfirmModalTxt.cancelTitle,
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then((v) => {
						if (!v) {
							ChangeChildrenContentType = false;
						} else {
							ChangeChildrenContentType = true;
						}
						// this.$apollo
						// 	.mutate({
						// 		mutation: mutation_setAsDep,
						// 		variables: {
						// 			BudgetDetailId,
						// 			ChangeChildrenContentType
						// 		}
						// 	})
						setAsDepartment(BudgetDetailId, ChangeChildrenContentType)
							.then((result) => {
								if (result) {
									this.loadDeps();
									this.reloadData();
								}
							})
							.catch((error) => {
								// alert('errora 3');
								console.log(error);
								this.erreur = error;
							});
					})
					.catch((err) => console.log(err));
			} else {
				// this.$apollo
				// 	.mutate({
				// 		mutation: mutation_setAsDep,
				// 		variables: {
				// 			BudgetDetailId,
				// 			ChangeChildrenContentType
				// 		}
				// 	})
				setAsDepartment(BudgetDetailId, ChangeChildrenContentType)
					.then((result) => {
						if (result) {
							this.loadDeps();
							this.reloadData();
						}
					})
					.catch((error) => {
						// alert('errora 4');
						console.log(error);
						this.erreur = error;
					});
			}
		},
		async addChild(item) {
			await CheckBudgetDetailExpenseSalary(item.id).then((result) => (this.isUsedBy = result));
			let DepartmentNumber = 0;
			if (this.curDep.value >= 0 || this.displayAllCategories) {
				DepartmentNumber = item.data.department;
			} else {
				DepartmentNumber = item.data.department;
			}
			if (DepartmentNumber == undefined) {
				console.log('crosss the condition ');
				DepartmentNumber = 0;
			}
			if (this.isUsedBy) {
				let txt = '';
				if (item.data.contentType == 0) {
					txt = this.FormMSG(
						90000,
						`The parent category "${item.data.description}" is used in at least one expense or PO, and/or an initial budget has been defined. If you continue, the associated expenses and POs will be associated with the created child category, as well as the initial budget. Do you want to continue?`
					);
				} else {
					txt = this.FormMSG(
						90001,
						`The parent category "${item.data.description}" is used in at least one contract and/or an initial budget has been defined. If you continue, the contracts will be associated with the created child category, as well as the initial budget. Do you want to continue?`
					);
				}
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.rendConfirmModalTxt.title,
						size: 'md',
						buttonSize: 'md',
						okVariant: 'primary',
						cancelVariant: 'danger',
						okTitle: this.rendConfirmModalTxt.okTitle,
						cancelTitle: this.rendConfirmModalTxt.cancelTitle,
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then((v) => {
						if (!v) return;
						this.addNewCats(DepartmentNumber, item);
					})
					.catch((err) => console.log(err));
			} else {
				this.addNewCats(DepartmentNumber, item);
			}
		},
		addNewCats(DepartmentNumber, item) {
			// this.$apollo
			// 	.mutate({
			// 		mutation: mutation_newCat,
			// 		variables: {
			// 			DepartmentNumber,
			// 			ParentId: item.id
			// 		}
			// 	})
			addBudgetDetail(DepartmentNumber, item.id)
				.then((result) => {
					console.log(result);
					this.newCreated = result.id;
					this.newCat = result;
					this.editModal = true;
					this.reloadData();
				})
				.catch((error) => {
					// alert('errora 5');
					// console.log(error)
					this.erreur = error;
				});
		},
		async updatedContentTypeAndCoTwo(item) {
			await this.SelectedCategorie(item, true);
			this.updChild();
		},
		deleteCatsInModal(item) {
			if (this.isNewCats) {
				this.delItem(item.id);
			}
		},
		async SelectedCategorie(item, update) {
			// console.log(item);
			if (item.estimatedFinalCost == item.initialBudget) {
				this.isEqual = true;
			}
			this.isNewCats = false;
			let selectedTreeCats = this.treeCatsSelect;
			this.treeCatsSelect = this.removeCategory(selectedTreeCats, item.ID);
			await CheckBudgetDetailExpenseSalary(item.ID).then((result) => {
				this.isUsedBy = result;
				this.curCat = item;
				this.newCat = Object.assign({}, item);
				this.newCat.id = item.ID;
				if (!update) {
					this.editModal = true;
				}
			});
		},
		checkChange(value) {
			if (value == true) {
				this.newCat.appChoice = false;
			}
		},
		updateDoconomyCategory(payload) {
			this.newCat.doconomyCategory = payload.doconomyCategory;
		},
		async updatedCostCenter(item) {
			await this.SelectedCategorie(item, true);
			// console.log("updatedCostCenter:",item);
			this.handleUpd(item);
		},
		async updateAmount(data) {
			await this.SelectedCategorie(data, true);
			this.curCatUpd(data);
		},
		appChoiceChanged() {
			//console.log("appchoice:",this.newCat.appChoice);
			if (this.newCat.appChoice) {
				// console.log("in newcat==1 this.newCat.description, ",this.newCat.description);
				this.newCat.appName = newCat.description;
			} else {
				// console.log("in newcat==false this.newCat.appChoice, ",this.newCat.appChoice);
				this.newCat.appName = '';
				// console.log("in newcat==false this.newCat.appName, ",this.newCat.appName);
			}
		},
		getCurrency() {
			return store.state.myProfile.currencySymbol;
		},
		displayAllCats() {
			// console.log("in displayAllCats", this.displayAllCategories)
			this.$nextTick(function () {
				this.reloadData();
			});
		},
		depChanged(evt) {
			this.curDep.value = evt;
			this.displayAllCategories = false;
			this.reloadData();
		},
		parentChange(value, event) {
			this.newCat.parentId = value;
		},
		createNewCats() {
			if (this.curDep.value == -1 || this.curDep.value == 0) {
				this.NewCategory(0);
			} else {
				const cats = this.perCats;
				for (const item of cats) {
					this.parentId = item.id;
				}
				this.NewCategory(this.parentId);
			}
		},
		removeCategory(nodes, selectedId) {
			if (!Array.isArray(nodes)) {
				return this.removeCategory([nodes], selectedId)[0];
			}
			return nodes
				.map((node) => {
					if (!node) return null;
					if (node.id === selectedId) return null;
					if (node.data && node.data.isSum == false) return null;
					let newNode = { ...node };
					if (newNode.children) {
						newNode.children = node.children.map((child) => this.removeCategory(child, selectedId)).filter((val) => val !== undefined);
					} else {
						return newNode;
					}
					return newNode;
				})
				.filter((val) => val !== null);
		},
		updatedContentTypeAndCoTwo2(data) {
			if (data.isSum == true) {
				this.updatedContentTypeAndCoTwo(data);
			} else {
				this.updatedCostCenter(data);
			}
		},
		addKeyToObjects(obj) {
			if (Array.isArray(obj)) {
				return obj.map((item) => this.addKeyToObjects(item));
			} else if (typeof obj === 'object') {
				obj.key = obj.id;
				if (Array.isArray(obj.children)) {
					obj.children = this.addKeyToObjects(obj.children);
				}
				return obj;
			}
		},
		async loadParent(Dep) {
			let Department;
			if (this.isFilmSingle) {
				Department = 0;
			} else {
				Department = Dep;
			}
			this.isLoading = true;
			await getBudgetHierarchicalCategoryJson(Department, false, null)
				.then((result) => {
					if (result == null) {
						this.perCats = [];
					} else {
						this.perCats = [];
						// if (Department === 0) {
						// 	this.perCats = this.addKeyToObjects(result);
						// } else if (Department !== 0 && result.length === 1) {
						// 	this.perCats = this.addKeyToObjects(result[0].children);
						// } else {
						// }
						this.perCats = this.addKeyToObjects(result);
						this.treeCatsSelect = [];
						this.treeCatsSelect = removeAttributeTree(result, 'children', null);
						if (result.filter((item) => parseInt(item.id, 10) === 0).length === 0) {
							this.treeCatsSelect = [
								{
									id: null,
									label: this.FormMSG(76, 'Select a category')
								},
								...this.treeCatsSelect
							];
						}
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		loadDeps() {
			getDepartments()
				.then((result) => {
					if (this.isFilmSingle) {
						for (let i = 0; i < result.length; i++) {
							const Department = result[i];
							if (Department.value == 0) {
								this.Deps.push(Object.assign({}, result[i]));
								this.curDep.value = 0;
								this.curDep.message = this.Deps[i].message;
								this.Deps[i].text = this.Deps[i].message;
							}
						}
					} else {
						const res = result.map((deps) => {
							return {
								text: deps.message,
								...deps
							};
						});
						this.Deps = res;
						// this.Deps.push(Object.assign({}, this.allDeps));
						// this.Deps[0].text = this.FormMSG(21, 'All departments'); //this.Deps[0].message;
						//console.log("this.Deps[0].message",this.Deps[0].message);
						// for (let i = 0; i < result.data.GetDepartments.length; i++) {
						// 	this.Deps[i] = Object.assign({}, result.data.GetDepartments[i]);
						// 	this.Deps[i].text = this.Deps[i].message;
						// }
					}

					//              this.curDep.value = 10;
					// if (store.isFilmSingleUserFree() || store.isFilmSingleUser()) {
					// 	console.log("in reloadData myprofile",store.state.myProfile);
					// 	this.curDep.value = store.state.myProfile.department;  ////TODO
					// 	console.log("in loadDeps curdep1",this.curDep.value);
					// 	this.reloadData();
					// }
				})
				.catch((error) => {
					// alert('errora 6');
					// console.log(error)
					this.erreur = error;
				});
		},
		fillUpdatedCategory() {
			let UpdatedBuDe = {};
			if (this.newCat.appChoice == false) {
				// blank appName
				this.newCat.appName = '';
			}
			UpdatedBuDe.vatPercent = parseFloat(this.newCat.vatPercent);
			UpdatedBuDe.vatPaidBack = parseFloat(this.newCat.vatPaidBack);
			UpdatedBuDe.appChoice = this.newCat.appChoice;
			UpdatedBuDe.isSum = this.newCat.isSum;
			UpdatedBuDe.parentId = parseInt(this.newCat.parentId);
			UpdatedBuDe.description = this.newCat.description;
			UpdatedBuDe.appName = this.newCat.appName;
			UpdatedBuDe.costCenter = this.newCat.costCenter;
			UpdatedBuDe.costCenterA = this.newCat.costCenterA;
			UpdatedBuDe.costCenterB = this.newCat.costCenterB;
			UpdatedBuDe.costCenterC = this.newCat.costCenterC;
			UpdatedBuDe.comment = this.newCat.comment;
			UpdatedBuDe.departmentName = this.newCat.departmentName;
			UpdatedBuDe.DepartmentNumber = this.newCat.DepartmentNumber;
			UpdatedBuDe.category = this.category;
			UpdatedBuDe.doconomyCategory = this.newCat.doconomyCategory;
			UpdatedBuDe.coTwoCategory = this.newCat.coTwoCategory;
			UpdatedBuDe.contentType = this.newCat.contentType;
			UpdatedBuDe.usedForCarbonClapForecastReport = this.newCat.usedForCarbonClapForecastReport;
			UpdatedBuDe.initialBudget = parseFloat(this.newCat.initialBudget);
			UpdatedBuDe.startOfPeriodValue = parseFloat(this.newCat.startOfPeriodValue);
			UpdatedBuDe.estimatedFinalCost = parseFloat(this.newCat.estimatedFinalCost);

			return UpdatedBuDe;
		},
		async UpdDepartment(props) {
			await updateDepartment(parseInt(this.curDep.id, 10), this.newCat.description).then(() => {
				this.curCatUpd(props);
				this.loadDeps();
			});
		},
		async UpdFunction(props) {
			await updateFunction(parseInt(this.newCat.FunctionId, 10), this.newCat.description).then(() => {
				this.curCatUpd(props);
			});
		},
		updChild() {
			const txt = this.FormMSG(989830, "Do you wish to apply the current changes to this categorie's children too?");

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.rendConfirmModalTxt.title,
					size: 'md',
					buttonSize: 'md',
					okVariant: 'primary',
					cancelVariant: 'danger',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((v) => {
					if (!v) {
						this.curCatUpd(false);
					} else {
						this.curCatUpd(false, true);
					}
				})
				.catch((err) => console.log(err));
		},
		handleUpd(item) {
			// console.log(item);
			if (!item) {
				// alert('Tatii');
				// if (this.curCat.isLinkedToDepartment) {
				// 	this.updateSalary();
				// } else if (this.curCat.IsLinkedToFunction) {
				// 	this.updateSalary();
				// } else
				if (
					(this.newCat.contentType != this.curCat.contentType && this.newCat.isSum) ||
					(this.newCat.coTwoCategory != this.curCat.coTwoCategory && this.newCat.isSum)
				) {
					this.updChild(false);
				} else {
					this.curCatUpd(false);
				}
			} else {
				if (item.isLinkedToDepartment) {
					this.updateSalary(item);
				} else if (item.IsLinkedToFunction) {
					this.updateSalary(item);
				} else {
					this.curCatUpd(item);
				}
			}
		},
		updateSalary(item) {
			const txt = this.FormMSG(
				98983,
				'Changing the cost center or the name of this category will also change the reference or the name of the function. Do you want to continue?'
			);

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.rendConfirmModalTxt.title,
					size: 'md',
					buttonSize: 'md',
					okVariant: 'primary',
					cancelVariant: 'danger',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((v) => {
					if (!v) return;
					if (!item) {
						if (
							(this.newCat.contentType != this.curCat.contentType && this.newCat.isSum) ||
							(this.newCat.coTwoCategory != this.curCat.coTwoCategory && this.newCat.isSum)
						) {
							this.updChild();
						}
						// else if (this.curCat.isLinkedToDepartment) {
						// 	this.UpdDepartment();
						// } else if (this.curCat.IsLinkedToFunction) {
						// 	this.UpdFunction();
						// }
						else {
							this.curCatUpd();
						}
					} else {
						// if (item.isLinkedToDepartment) {
						// 	this.UpdDepartment(item);
						// } else if (item.IsLinkedToFunction) {
						// 	this.UpdFunction(item);
						// } else {
						this.curCatUpd(item);
						// }
					}
				})
				.catch((err) => console.log(err));
		},
		curCatUpd(reload, UpdChildren = false) {
			// alert('test');
			//console.log("before call depId:", this.newCat);
			// loading variables from newDep
			const BudgetDetailId = parseInt(this.newCat.id, 10);
			const UpdatedBuDe = this.fillUpdatedCategory();
			let UpdArgument = 0;
			if (this.curDep.value != -1) {
				UpdArgument = 1;
			}

			updateBudgetDetail(BudgetDetailId, UpdatedBuDe, UpdArgument, UpdChildren)
				.then((result) => {
					if (!reload) {
						this.reloadData();
					}
					this.editModal = false;
					this.createToastForMobile(this.FormMSG(155, 'Success'), this.FormMSG(75, 'Update successfull'), '', 'success');
					// modify curCat from newCat
					this.curCat = Object.assign({}, this.newCat);
					// find object in the list and update accordingly
					//console.log("after cat update result:", result);
					for (let i = 0; i < this.Cats.length; i++) {
						if (this.Cats[i].id == result.data.UpdBudgetDetail.id) {
							this.Cats[i].description = this.curCat.description;
							this.Cats[i].category = this.curCat.category;
							this.Cats[i].vatPercent = this.curCat.vatPercent;
							this.Cats[i].vatPaidBack = this.curCat.vatPaidBack;
							this.Cats[i].appChoice = this.curCat.appChoice;
							this.Cats[i].appName = this.curCat.appName;
							this.Cats[i].isSum = this.curCat.isSum;
							this.Cats[i].parentId = this.curCat.parentId;
							this.Cats[i].usedForCarbonClapForecastReport = this.curCat.usedForCarbonClapForecastReport;
							this.Cats[i].costCenter = this.curCat.costCenter;
							this.Cats[i].costCenterA = this.curCat.costCenterA;
							this.Cats[i].costCenterB = this.curCat.costCenterB;
							this.Cats[i].costCenterC = this.curCat.costCenterC;
							this.Cats[i].departmentName = this.curCat.departmentName;
							this.Cats[i].comment = this.curCat.comment;
							this.Cats[i].doconomyCategory = this.curCat.doconomyCategory;
							this.Cats[i].initialBudget = this.curCat.initialBudget;
							this.Cats[i].startOfPeriodValue = this.curCat.startOfPeriodValue;
							this.Cats[i].estimatedFinalCost = this.curCat.estimatedFinalCost;

							break;
						}
					}
					// alert(reload);

					//this.successModal = true;
				})
				.catch((error) => {
					// if (error.graphQLErrors[0].status === 503) {
					// 	this.$toast({
					// 		message: this.FormMSG(410, 'This category cannot be deleted because this is the only category classed as transport'),
					// 		title: this.FormMSG(411, 'Error'),
					// 		variant: 'danger'
					// 	});
					// } else {
					// 	this.erreur = error;
					// }
				});
		},
		updated: function () {
			this.$nextTick(function () {
				// Code that will run only after the
				// entire view has been re-rendered
				console.log('in updated');
			});
		},
		async loadCats(DepartmentNumber) {
			let Dep;
			if (this.isFilmSingle) {
				Dep = 0;
			} else {
				Dep = DepartmentNumber;
			}
			await GetBudgetCategoryList(Dep)
				.then((result) => {
					this.Cats = result;
					if (this.newCreated > 0) {
						// find and select the newly created
						for (let i = 0; i < this.Cats.length; i++) {
							if (this.Cats[i].id == this.newCreated) {
								this.curCat = this.Cats[i];
								this.newCat = Object.assign({}, this.curCat);
								if (this.isFilmSingle) {
									this.newCat.costCenter = '';
								}
								this.Cats[i].description = this.curCat.description;
								this.clickRow(i);

								break;
							}
						}
						this.newCreated = 0;
					} else {
						// select element
						this.curCat = this.Cats[0];
						this.newCat = Object.assign({}, this.curCat);
						this.clickRow(0);
					}
				})
				.catch((error) => {
					// alert('errora 7');
					// console.log(error)
					this.erreur = error;
				});
		},
		reloadData() {
			this.erreur = {};
			//console.log("in reloadData this.Deps:",this.Deps);
			if (this.Deps.length == 0) {
				this.loadDeps();
			}

			let DepartmentNumber = this.curDep.value;
			if (this.displayAllCategories) {
				DepartmentNumber = -2;
			}
			if (this.isFilmSingle) {
				DepartmentNumber = 0;
				this.curDep.value = 0;
			}
			setTimeout(() => {
				this.loadParent(DepartmentNumber);
				// updating curDep.message
				for (const department of this.Deps) {
					if (this.curDep.value == department.value) {
						this.curDep.message = department.message;
						this.curDep.id = department.id;
						//console.log("this.curDep.message:", this.curDep.message);
						break;
					}
				}
				this.loadCats(DepartmentNumber);
			}, 250);
		},
		async delItemConfirm(item) {
			await CheckBudgetDetailExpenseSalary(item.id).then((result) => (this.isUsedBy = result));
			if (this.isUsedBy) {
				let txts = '';
				if (item.data.contentType == 0) {
					txts = this.FormMSG(90002, `The category cannot be deleted because it is used in at least one expense or PO`);
				} else {
					txts = this.FormMSG(90003, `The budget category cannot be deleted, it is currently used in at least one contract.`);
				}
				this.$bvModal.msgBoxOk(txts, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(12034, 'Close'),
					footerClass: 'p-2',
					okOnly: true,
					hideHeaderClose: false,
					centered: false
				});
			} else {
				const txt = this.FormMSG(98982, 'Please confirm that you want delete this category, this category (and his sub-category) will be deleted');
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.rendConfirmModalTxt.title,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: this.rendConfirmModalTxt.okTitle,
						cancelTitle: this.rendConfirmModalTxt.cancelTitle,
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then((v) => {
						if (!v) return;
						this.delItem(item.id);
					})
					.catch((err) => console.log(err));
			}
		},
		delItem(id) {
			const BudgetDetailTreeParentId = parseInt(id, 10);
			// this.$apollo
			// 	.mutate({
			// 		mutation: mutation_curCatDel,
			// 		variables: {
			// 			BudgetDetailTreeParentId
			// 		}
			// 	})
			delBudgetDetailTree(BudgetDetailTreeParentId)
				.then((result) => {
					const buDe = result.data.DelBudgetDetailTree;
					console.log(buDe);
					if (buDe.comment == 'INUSE') {
						var str =
							this.FormMSG(231, 'Unauthorized delete, category still in use by department:') +
							' ' +
							buDe.departmentName +
							' (' +
							buDe.description +
							')';
						var strTitle = this.FormMSG(232, 'Error');
						//alert(str);
						//console.log("del dep buDe:", buDe);
						this.$bvModal.msgBoxOk(str, {
							title: strTitle,
							size: 'md',
							buttonSize: 'md',
							okVariant: 'success',
							headerClass: 'p-2 border-bottom-0',
							footerClass: 'p-2 border-top-0',
							centered: false
						});
					} else {
						this.reloadData();
					}
					//this.successModalDelete = true;
				})
				.catch((error) => {
					console.log(error);
					if (error.graphQLErrors[0].status === 503) {
						this.createToastForMobile(
							this.FormMSG(411, 'Error'),
							this.FormMSG(410, 'This category cannot be deleted because this is the only category classed as transport'),
							'',
							'danger'
						);
					} else {
						// alert('errora 8');
						this.erreur = error;
					}
				});
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClickedMobile(item) {
			this.curCat = item;
			this.newCat = Object.assign({}, item);
			this.editModal = true;
		},
		NewCategory(id) {
			console.log('New Category triggered');
			const DepartmentNumber = this.curDep.value;
			if (DepartmentNumber == undefined) {
				console.log('crosss the condition ');
				DepartmentNumber = 0;
			}
			// this.$apollo
			// 	.mutate({
			// 		mutation: mutation_newCat,
			// 		variables: {
			// 			DepartmentNumber,
			// 			ParentId: id
			// 		}
			// 	})
			addBudgetDetail(DepartmentNumber, id)
				.then((result) => {
					// reload the Data
					// save new to newCat
					this.newCreated = result.id;
					this.newCat = result;
					this.isNewCats = true;
					this.editModal = true;
					this.reloadData();
				})
				.catch((error) => {
					// alert('errora 9');
					// console.log(error)
					this.erreur = error;
				});
		},
		clickRow(idx) {
			this.currentPage = parseInt(parseInt(idx, 10) / parseInt(this.perPage, 10), 10) + 1;
			this.$nextTick(function () {
				// Code that will run only after the
				// entire view has been re-rendered
				// found in https://jsfiddle.net/sem409uc/
				if (!isNil(this.$refs.myTable)) {
					let myTable = this.$refs.myTable.$el,
						tableBody = myTable.getElementsByTagName('tbody')[0],
						tableRows = tableBody.getElementsByTagName('tr');
					tableRows[idx].click();
				}
			});
		}
	},
	created() {
		this.reloadData();
	}
};
</script>
